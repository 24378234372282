// utils
import { ReactNode, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useFetch } from "../../../hooks/useFetch";
import Moment from "react-moment";

// spinner
import Spinner from "../../spinner/Spinner";

// types
import { Field } from "../../../types/field";
import { Store } from "../../../types/store";
import { AvailableTime } from "../../../types/availableTime";
import { FirstStepFormData } from "../../../types/formData";

// material
import {
  MenuItem,
  Select,
  RadioGroup,
  Radio,
  FormControlLabel,
  Box,
  Button,
} from "@mui/material";

// styles
import "./FirstStep.css";
import moment from "moment";

type FirstStepProps = {
  onSubmit: (data: FirstStepFormData) => void;
  data?: FirstStepFormData;
};

const matchDurations: string[] = [
  "Media hora",
  "1 Hora",
  "1 Hora y media",
  "2 Horas",
  "2 Horas y media",
  "3 Horas",
  "3 Horas y media",
  "4 Horas",
];

export const FirstStep: React.FC<FirstStepProps> = ({ onSubmit, data }) => {
  const defaultValues = {
    store: data?.store || "",
    field: data?.field || "",
    duration: data?.duration || "",
    date: data?.date || "",
    matchTime: data?.matchTime || "",
  };

  const { watch, control, register, resetField, setValue } =
    useForm<FirstStepFormData>({
      defaultValues,
      mode: "onTouched",
    });

  const currentStoreId = watch("store");

  const currentFielId = watch("field");

  const currentDuration = watch("duration");

  const currentDate = watch("date");

  const currentMatchTime = watch("matchTime");

  const getFieldType = (id: number): String => {
    if (!fieldsData) {
      return "";
    }

    const name = fieldsData.find((field) => {
      return field.id === id;
    })?.field_type?.name;

    return name || "";
  };


  // process.env.REACT_APP_PROD_API_SUCURSALES;
  // process.env.REACT_APP_PROD_API_CANCHAS;
  // process.env.REACT_APP_PROD_API_DISPONIBILIDAD;

  // useFetch for stores, is always executed at least one time
  const { data: storesData, error: storesFetchError } = useFetch<Store[]>(
    process.env.REACT_APP_PROD_API_SUCURSALES!,
    { enabled: true }
  );

  // useFetch for fields
  const { data: fieldsData, isPending: isFieldsPending } = useFetch<Field[]>(
    process.env.REACT_APP_PROD_API_CANCHAS + currentStoreId,
    { enabled: Boolean(currentStoreId) }
  );

  const allowAPICall = (): Boolean => {
    let type = getFieldType(parseInt(currentFielId))
    if(type == 'Padel') {
      return true;
    }
    return !(type != 'Padel' && currentDuration != "60")
  }

  // useFetch to retrieve the available times
  // format of the parameters in the url ->{field_id}/{duration}/{date}
  const {
    data: availableTimes,
    isPending: isAvailableTimesPending,
    error: availableTimesError,
    setData: clearAvailibility,
  } = useFetch<AvailableTime[]>(
    `${process.env.REACT_APP_PROD_API_DISPONIBILIDAD}${currentFielId}/${currentDuration}/${currentDate}`,
    {
      enabled:
        Boolean(currentFielId) &&
        Boolean(currentDuration) &&
        Boolean(currentDate) &&
        Boolean(allowAPICall()),
    }
  );

  const minDate = new Date();

  const maxDate = new Date();

  maxDate.setDate(minDate.getDate() + 6);

  const setBranchOfficeId = (value: any) => {
    setValue("store", value);
  };

  (window as any).setBranchOfficeId = setBranchOfficeId;

  useEffect(() => {
    resetField("matchTime");
  }, [currentDate, currentDuration, currentFielId, resetField]);
  // functions to create the options for our inputs

  useEffect(() => {
    clearAvailibility(null);
  }, [currentStoreId, clearAvailibility]);

  useEffect(() => {
    if(currentFielId != '' && (currentDuration != "60" && getFieldType(parseInt(currentFielId)) != 'Padel')) {
      setValue("duration", "60");
    }
  }, [currentFielId])

  const createMatchDurationOptions = (durations: string[]): ReactNode => {
    let type = getFieldType(parseInt(currentFielId));
    let initialMinutes = type == 'Padel' ? 0 : 30;

    durations = type != 'Padel' ? ["1 Hora"] : durations;

    return durations.map((duration) => {
      return (
        <MenuItem key={initialMinutes} value={(initialMinutes += 30)}>
          {duration}
        </MenuItem>
      );
    });
  };

  const createStoreOptions = (stores: Store[]): ReactNode => {
    if (!stores || !Array.isArray(stores)) {
      return [];
    }

    return stores.map((store) => {
      return (
        <MenuItem key={store.id} value={store.id}>
          {store.name}
        </MenuItem>
      );
    });
  };

  const createFieldOptions = (fields: Field[]): ReactNode => {
    if (!fields || !Array.isArray(fields)) {
      return [];
    }

    return fields.map((field) => {
      return (
        <MenuItem key={field.id} value={field.id}>
          {field.name}
        </MenuItem>
      );
    });
  };

  const createAvailableTimesRadioOptions = (
    availableTimes: AvailableTime[]
  ): ReactNode => {
    if (!availableTimes || !Array.isArray(availableTimes)) {
      return [];
    }

    return availableTimes.map((availableTime) => {
      return (
        <FormControlLabel
          value={availableTime.time}
          control={<Radio />}
          label={availableTime.time}
          sx={{ marginTop: "-5px" }}
          key={availableTime.time}
        />
      );
    });
  };

  const handleSubmit = () => {
    const data: FirstStepFormData = {
      store: currentStoreId,
      field: currentFielId,
      duration: currentDuration,
      date: currentDate,
      matchTime: currentMatchTime,
      price: getPrice(currentMatchTime),
      field_name: getFieldName(parseInt(currentFielId)),
      store_name: getStoreName(parseInt(currentStoreId)),
    };

    onSubmit(data);
  };

  const getPrice = (time: string): number => {
    if (!availableTimes) {
      return 0;
    }

    let price = 0;
    for (let index = 0; index < availableTimes!.length; index++) {
      if (availableTimes[index].time === time) {
        price = availableTimes[index].price;
        break;
      }
    }

    return price;
  };

  const getStoreName = (id: number) => {
    if (!storesData) {
      return "";
    }

    const name = storesData.find((store) => {
      return store.id === id;
    })?.name;

    return name || "";
  };

  const getFieldName = (id: number): string => {
    if (!fieldsData) {
      return "";
    }

    const name = fieldsData.find((field) => {
      return field.id === id;
    })?.name;

    return name || "";
  };


  // method onSubmit comes from the parent component
  return (
    <div className="container-step-1">
      <form id="first-step" className="first-step-form" onSubmit={handleSubmit}>
        <hr />
        <span className="title">
          Selecciona la sucursal, cancha y duración del partido, para la fecha
          que quieras
        </span>
        <hr />

        {storesFetchError && (
          <span>
            Ocurrió un error al cargar las sucursales, por favor reintente.
          </span>
        )}

        {storesData && (
          <>
            <div className="input-wrapper">
              <span>Sucursal</span>
              <Controller
                control={control}
                name="store"
                rules={{ required: "El campo es requerido" }}
                render={({ field: { ref, value, onChange, ...rest } }) => (
                  <Select
                    inputRef={ref}
                    value={value}
                    onChange={onChange}
                    {...rest}
                    className="select"
                    variant="standard"
                  >
                    {createStoreOptions(storesData)}
                  </Select>
                )}
              />
            </div>

            {fieldsData && (
              <>
                <div className="input-wrapper">
                  <span>Tipo de cancha</span>
                  {!isFieldsPending && (
                    <Controller
                      control={control}
                      name="field"
                      rules={{ required: "El campo es requerido" }}
                      render={({
                        field: { ref, value, onChange, ...rest },
                      }) => (
                        <Select
                          inputRef={ref}
                          value={value}
                          onChange={onChange}
                          {...rest}
                          className="select"
                          variant="standard"
                        >
                          {createFieldOptions(fieldsData)}
                        </Select>
                      )}
                    />
                  )}

                  {isFieldsPending && <Spinner />}
                </div>

                <div className="input-wrapper">
                  <span>Duración del partido</span>
                  <Controller
                    control={control}
                    name="duration"
                    rules={{ required: "El campo es requerido" }}
                    render={({ field: { ref, value, onChange, ...rest } }) => (
                      <Select
                        inputRef={ref}
                        value={value}
                        onChange={onChange}
                        {...rest}
                        className="select"
                        variant="standard"
                      >
                        {createMatchDurationOptions(matchDurations)}
                      </Select>
                    )}
                  />
                </div>

                <div className="date-picker">
                  <span>Fecha del partido</span>
                  <input
                    type="date"
                    min={new Date().toISOString().split("T")[0]}
                    max={moment().add(6, "days").format("YYYY-MM-DD")}
                    {...register("date", {
                      required: true,
                      min: minDate.toDateString(),
                      max: maxDate.toDateString(),
                    })}
                  />
                </div>

                <div className="time-picker-title">Horarios disponibles</div>

                {isAvailableTimesPending && (
                  <div className="spinner-container">
                    <Spinner />
                  </div>
                )}

                {availableTimesError && !isAvailableTimesPending && (
                  <>
                    <br />
                    <span>{availableTimesError}</span>
                  </>
                )}

                {availableTimes && (
                  <>
                    <div className="time-picker">
                      <Controller
                        control={control}
                        name="matchTime"
                        rules={{ required: true }}
                        render={({ field: { value, onChange, ...rest } }) => (
                          <RadioGroup
                            value={value}
                            onChange={onChange}
                            {...rest}
                            sx={{ marginTop: "20px", marginLeft: "20px" }}
                          >
                            {createAvailableTimesRadioOptions(availableTimes)}
                          </RadioGroup>
                        )}
                      />
                    </div>
                  </>
                )}

                {availableTimes && availableTimes.length === 0 && (
                  <>
                    <br />
                    <span>
                      No hay horarios disponibles en la fecha seleccionada.
                    </span>
                  </>
                )}
              </>
            )}
          </>
        )}
      </form>

      <Box
        className="button-container"
        sx={{
          display: "flex",
          flexDirection: "row",
          pt: 2,
          marginTop: "20px",
        }}
      >
        <Box sx={{ flex: "1 1 auto" }} />
        <Button form="first-step" type="submit" disabled={!currentMatchTime}>
          Continuar
        </Button>
      </Box>
    </div>
  );
};
