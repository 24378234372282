// utils
import React from "react";

// our steps
import { FirstStep } from "./firstStep/FirstStep";
import { SecondStep } from "./secondStep/SecondStep";
import { ThirdStep } from "./thirdStep/ThirdStep";

//styles
import "./ReservationForm.css";

// material
import { Step, StepLabel } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Box from "@mui/material/Box";

//types
import { FormData } from "../../types/formData";

const steps = ["Datos del partido", "Datos del cliente", "Confirmacion"];

export default function ReservationForm() {
  // default values

  const [formData, setFormData] = React.useState<FormData>({
    store: "",
    field: "",
    duration: "",
    date: "",
    name: "",
    email: "",
    phone: "",
    matchTime: "",
    price: 0,
    clientId: 0,
    surname: "",
  });

  const [activeStep, setActiveStep] = React.useState(0);

  const goFoward = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const goBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  const getStepContent = (index: number) => {
    switch (index) {
      case 0:
        return (
          <FirstStep
            data={formData}
            onSubmit={(data) => {
              goFoward();

              setFormData((previousValue) => {
                return { ...previousValue, ...data };
              });
            }}
          />
        );

      case 1:
        return (
          <SecondStep
            onBackBtnClick={goBack}
            data={formData}
            fieldId={parseInt(formData.field)}
            onSubmit={(data) => {
              goFoward();

              setFormData((previousValue) => {
                return { ...previousValue, ...data };
              });
            }}
          />
        );
      // lastStep
      default:
        return (
          <ThirdStep
            onBackBtnClick={goBack}
            // data to show
            data={formData}
          />
        );
    }
  };

  return (
    <Box className="stepper-container">
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          const stepProps: { completed?: boolean } = {};

          return (
            <Step key={label} {...stepProps} className="step">
              <StepLabel className="title-label"> {label} </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "25px",
          }}
        >
          {getStepContent(activeStep)}
        </Box>
      </>
    </Box>
  );
}
