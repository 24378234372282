import { useState, useEffect } from "react";

interface UseFetchOptions {
  method?: string;
  enabled?: boolean;
}

type PostData = (postData: Record<string, unknown>) => void;

export function useFetch<T>(url: string, config: UseFetchOptions) {
  const { method, enabled } = config;

  const [data, setData] = useState<T | null>(null);
  const [isPending, setIsPending] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [options, setOptions] = useState<RequestInit | null>(null);

  const postData: PostData = (postData) => {
    // building up an option object to use when a POST is required
    setOptions({
      method: method || "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(postData),
    });
  };

  useEffect(() => {
    const controller = new AbortController();

    if (!enabled) {
      return;
    }

    const fetchData = async (fetchOptions?: RequestInit) => {
      setIsPending(true);
      try {
        const res = await fetch(url, {
          ...fetchOptions,
          signal: controller.signal,
        });

        if (!res.ok) {
          const msj = await res.text();
          throw msj;
        }

        const data = await res.json();
        setIsPending(false);
        setData(data);
        setError(null);
      } catch (err) {
        if (typeof err == "string") {
          setError(err as string);
          setIsPending(false);
        } else {
          if ((err as Error).name === "AbortError") {
            console.log("the fetch was aborted");
          }
          setIsPending(false);
          setError("Error de conexion, intente nuevamente.");
        }
      }
    };

    if (method === "GET" || !method) {
      // Default behavior of useFetch function
      fetchData();
    }

    if (method === "POST" && options) {
      fetchData(options);
    }

    return () => {
      setData(null);
      setError(null);
      controller.abort();
    };
  }, [url, options, method, enabled]);
  // dependencias tendrian q ser primitivas (intentarlo)
  // en todo caso usar objeto.propiedad

  return { data, isPending, error, postData, setData };
}
