// our components
import ReservationForm from "./components/reservation-form/ReservationForm";

// styles
import "./App.css";

// material
import { Card } from "@mui/material";

// utils
import { useEffect, useState } from "react";

// types
import AfterPaymentStatus, {
  AfterPaymentStatusData,
} from "./components/afterpaymentstatus/AfterPaymentStatus";

function App() {

  const [isPaymentDone, setIsPaymentDone] = useState(false);
  const [reservationData, setReservationData] =
    useState<AfterPaymentStatusData>({
      reservation_date: "",
      paid_amount: 0,
      branch_office: "",
      time: "",
      transaction_status: "",
    });

  useEffect(() => {
    let params = new URLSearchParams(document.location.search);
    // scroll to bottom

    if (!params.get("transaction_status")) {
      return;
    }

    const div: HTMLElement = document.getElementById("reservation")!;
    div.scrollIntoView(true);

    setReservationData({
      transaction_status: params.get("transaction_status")!,
      paid_amount: parseInt(params.get("paid_amount")!),
      reservation_date: params.get("reservation_date")!,
      time: params.get("time")!,
      branch_office: params.get("branch_office")!,
    });

    setIsPaymentDone(true);
  }, []);

  return (
    <div className="App">
      {isPaymentDone ? (
        <>
          <Card className="reservation-form-container">
            <AfterPaymentStatus
              paid_amount={reservationData.paid_amount}
              reservation_date={reservationData.reservation_date}
              time={reservationData.time}
              branch_office={reservationData.branch_office}
              transaction_status={reservationData.transaction_status}
            ></AfterPaymentStatus>
          </Card>
        </>
      ) : (
        <>
          <h1>¡ Hace tu reserva !</h1>
          <Card className="reservation-form-container">
            <ReservationForm />
          </Card>
        </>
      )}
    </div>
  );
}

export default App;
