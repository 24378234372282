// mercado pago logo
import mplogo from "../../../assets/mplogo.png";

// types
import { FormData } from "../../../types/formData";
import { ReservationResponse } from "../../../types/reservationResponse";

// utils
import { useFetch } from "../../../hooks/useFetch";
import { useEffect, useState } from "react";
import moment from "moment";

// material
import { Box, Button } from "@mui/material";

// styles
import "./ThirdStep.css";

// spinner
import Spinner from "../../spinner/Spinner";

interface ThirdStepProps {
  onBackBtnClick: () => void;
  data: FormData;
}

// dd mm yyyy

export const ThirdStep: React.FC<ThirdStepProps> = ({
  data,
  onBackBtnClick,
}) => {
  const [isRequestRedy, setIsRequestRedy] = useState(false);

  const {
    data: reservationResponseData,
    postData: reservationData,
    setData: clearReservationData,
    isPending: isReservationResponsePending,
    error: reservationError,
  } = useFetch<ReservationResponse>(
    process.env.REACT_APP_PROD_API_RESERVAR + data.field,
    {
      method: "POST",
      enabled: true,
    }
  );

  const handleConfirmButtonClick = () => {
    const requestBody = {
      duration: data.duration,
      time: data.matchTime,
      date: data.date,
      clientId: data.clientId,
      branch_office_id: data.store,
      name: data.name,
      surname: data.surname,
      phone: data.phone,
      email: data.email,
      host: window.location.host,
    };

    setIsRequestRedy(true);
    reservationData(requestBody);
  };

  useEffect(() => {
    clearReservationData(null);
  }, []);

  return (
    <div className="third-step">
      <hr />
      <span className="title">
        Confirma la información sobre tu reserva, y procede al pago
      </span>

      <div className="columns">
        <div className="first-column">
          <hr />
          <span className="information-wrapper">
            <p>Sucursal:</p>
            <p>{data.store_name}</p>
          </span>

          <span className="information-wrapper">
            <p>Tipo de cancha:</p>
            <p>{data.field_name}</p>
          </span>

          <span className="information-wrapper">
            <p>Duración del partido:</p>
            <p>{data.duration + " minutos"}</p>
          </span>

          <span className="information-wrapper">
            <p>Fecha y hora del partido:</p>
            <p>
              {moment(data.date).format("DD/MM/YYYY")}
              {" - "}
              {data.matchTime}
            </p>
          </span>

          <span className="information-wrapper">
            <p>Nombre: </p>
            <p>{data.name}</p>
          </span>

          <span className="information-wrapper">
            <p>Email: </p>
            <p>{data.email}</p>
          </span>

          <span className="information-wrapper">
            <p>Celular: </p>
            <p>{data.phone}</p>
          </span>

          <span className="information-wrapper">
            <p>Precio total: </p>
            <p>${data.price}</p>
          </span>
        </div>

        <div className="second-column">
          <img src={mplogo} alt="" />
        </div>
      </div>

      <Box
        className="button-container second-step-buttons"
        sx={{
          display: "flex",
          flexDirection: "row",
          pt: 2,
          marginTop: "20px",
        }}
      >
        {!reservationResponseData && (
          <Button color="inherit" onClick={onBackBtnClick}>
            Volver
          </Button>
        )}

        <Box sx={{ flex: "1 1 auto" }} />

        {!isRequestRedy && (
          <Button onClick={handleConfirmButtonClick}>
            ¡ Confirmar datos !
          </Button>
        )}

        {isReservationResponsePending && (
          <div className="spinner-container">
            <Spinner />
          </div>
        )}

        {reservationResponseData && (
          <a href={reservationResponseData.url} className="pay-button">
            <Button variant="contained">
              ¡ Pagar seña ! ${reservationResponseData.amount}
            </Button>
          </a>
        )}

        {reservationError && (
          <>
            <span className="error">{reservationError}</span>
          </>
        )}
      </Box>
    </div>
  );
};
