// utils
import { useFetch } from "../../../hooks/useFetch";
import FormInput from "../mui-controller/FormInput";
import React, { useEffect } from "react";
import InputMask from "react-input-mask";

// material
import { Box, Button, TextField } from "@mui/material";

// styles
import "./SecondStep.css";
import { Controller, useForm } from "react-hook-form";

// types
import { ClientData } from "../../../types/clientData";
import { SecondStepFormData } from "../../../types/formData";

interface SecondStepProps {
  onSubmit: (data: SecondStepFormData) => void;
  onBackBtnClick: () => void;
  data?: SecondStepFormData;
  fieldId?: number;
}

export const SecondStep: React.FC<SecondStepProps> = ({
  onSubmit,
  onBackBtnClick,
  data,
  fieldId,
}) => {
  const defaultValues = {
    phone: data?.phone || "",
    email: data?.email || "",
    name: data?.name || "",
    surname: data?.surname || "",
  };

  const {
    watch,
    formState: { isValid, isDirty },
    control,
    setValue,
  } = useForm<SecondStepFormData>({ defaultValues, mode: "onTouched" });

  const phoneCurrentValue = watch("phone");

  const nameCurrentValue = watch("name");

  const emailCurrentValue = watch("email");

  const surnameCurrentValue = watch("surname");

  const isCompleted = (): boolean => {
    return Boolean(
      phoneCurrentValue &&
        nameCurrentValue &&
        emailCurrentValue &&
        surnameCurrentValue
    );
  };

  // Nro de telefono (llamamos api con esto para ver si esta, si esta autocompletamos)
  // Nombre
  // email
  const { data: clientData } = useFetch<ClientData>(
    `${process.env.REACT_APP_PROD_API_CLIENTES}${fieldId}/${encodeURIComponent(
      phoneCurrentValue
    )}`,
    {
      enabled: !phoneCurrentValue.includes("_"),
    }
  );


  useEffect(() => {
    if (clientData) {
      if (clientData.name) {
        setValue("name", clientData.name);
      }

      if (clientData.surname) {
        setValue("surname", clientData.surname);
      }
    }
  }, [clientData, setValue]);

  const handleSubmit = () => {
    const data: SecondStepFormData = {
      surname: surnameCurrentValue,
      clientId: clientData?.id || 0,
      phone: phoneCurrentValue,
      name: nameCurrentValue,
      email: emailCurrentValue,
    };

    onSubmit(data);
  };

  return (
    <div className="second-step">
      <form
        id="second-step"
        className="second-step-form"
        onSubmit={handleSubmit}
      >
        <hr />
        <span className="title">
          Ingresa tus datos de contacto, te llamaremos si hay algún problema
        </span>
        <hr />

        <div className="input-wrapper">
          <span>Celular</span>
          <Controller
            name="phone"
            rules={{ required: true }}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputMask
                mask="(99) 9999-9999"
                onChange={onChange}
                value={value}
              >
                {/* @ts-ignore*/}
                {() => <TextField label="" />}
              </InputMask>
            )}
          />
        </div>

        <div className="input-wrapper">
          <span>Nombre</span>
          <FormInput
            control={control}
            name="name"
            readOnly={!!clientData}
            label=""
            sx={{ marginTop: "-15px" }}
            rules={{ required: true }}
          ></FormInput>
        </div>

        <div className="input-wrapper">
          <span>Apellido</span>

          <FormInput
            control={control}
            name="surname"
            readOnly={!!clientData}
            label=""
            sx={{ marginTop: "-15px" }}
            rules={{ required: true }}
          ></FormInput>
        </div>

        <div className="input-wrapper">
          <span>Email</span>
          <FormInput
            control={control}
            name="email"
            label=""
            type="email"
            sx={{ marginTop: "-15px" }}
            rules={{ required: true }}
          ></FormInput>
        </div>
      </form>
      <Box
        className="button-container second-step-buttons"
        sx={{
          pt: 2,
          marginTop: "20px",
          widht: "100%",
          display: "flex",
        }}
      >
        <Button color="inherit" onClick={onBackBtnClick}>
          Volver
        </Button>

        <Button form="second-step" type="submit" disabled={!isCompleted()}>
          Continuar
        </Button>
      </Box>
    </div>
  );
};
