import {
  FormControl,
  FormControlProps,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import { FunctionComponent, useState } from "react";
import { Control, Controller } from "react-hook-form";

interface FormInputProps extends FormControlProps {
  label: string;
  name: string;
  control: Control<any>;
  rules: {};
  type?: string;
  readOnly?: boolean;
}

const FormInput: FunctionComponent<FormInputProps> = ({
  name,
  label,
  type,
  control,
  rules,
  variant,
  readOnly,
  ...rest
}) => {
  const [pwVisible, setPwVisible] = useState(
    name === "password" || name === "repeatPassword" ? false : true
  );
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { error },
      }) => (
        <FormControl
          margin="dense"
          error={Boolean(error)}
          {...rest}
        >
          <InputLabel htmlFor={name}>{label}</InputLabel>
          <Input
            id={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            inputRef={ref}
            readOnly={readOnly}
            type={type || "text"}
            endAdornment={
              (name === "password" || name === "repeatPassword") && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setPwVisible((prev) => !prev)}
                    onMouseDown={() => setPwVisible((prev) => !prev)}
                    size="small"
                    edge="end"
                    disableRipple
                    disableFocusRipple
                    style={{ backgroundColor: "transparent" }}
                  >
                  </IconButton>
                </InputAdornment>
              )
            }
          />
          {error && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

export default FormInput;
