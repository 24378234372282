import "./AfterPaymentStatus.css";

// reservation_date={$date}&time={$timeStr}&paid_amount={$amount}&branch_office={$branchOffice}
export type AfterPaymentStatusData = {
  transaction_status: string;
  reservation_date: string;
  paid_amount: number;
  time: string;
  branch_office: string;
};

export default function AfterPaymentStatus(props: AfterPaymentStatusData) {
  const setMessage = (transactionStatus: string): string => {
    if (!transactionStatus) {
      return "";
    }

    let message = "";

    switch (transactionStatus) {
      case "succeeded":
        message = "¡ Pago de la reserva realizado con exito !";
        break;

      case "error":
        message =
          "Hubo un error al acreditar la transacción, por favor comunicate con nosotros para terminar el pago. ¡Recorda que la reserva ya esta hecha!. ";
        break;

      default:
        message =
          "Mercado pago aún no ha acreditado tu pago. Comunicate más tarde con nosotros para confirmar el estado de tu transacción, ¡recorda que tu reserva ya esta hecha!. ";
        // pending...
        break;
    }

    return message;
  };

  return (
    <div className="successfulPayment-wrapper">
      <h3>{setMessage(props.transaction_status)}</h3>
      <hr />

      <h4> Datos de la reserva </h4>

      <div className="reservation-data-wrapper">
        <span>Sucursal: </span>
        <span>{props.branch_office}</span>
      </div>

      <div className="reservation-data-wrapper">
        <span>Monto abonado:</span>
        <span>${props.paid_amount}</span>
      </div>

      <div className="reservation-data-wrapper">
        <span>Fecha del partido: </span>
        <span>{props.reservation_date}</span>
      </div>

      <div className="reservation-data-wrapper">
        <span>Hora del partido: </span>
        <span>{props.time}</span>
      </div>
    </div>
  );
}
